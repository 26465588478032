import React, {useEffect, useState} from 'react';
import Model from './Model';
import Address from './Address';
import userMock from './mocks/user';

//todo remove mock;
class User extends Model {
    static useSignIn() {
        const [authenticated, setAuthenticated] = useState(false);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);
        const [user, setUser] = useState(null);
        useEffect(() => {
            //todo remove mock;
            setUser(new User(userMock));
            setAuthenticated(true);
            return () => {
            }
        }, []);
        return {user, loading, error, authenticated}
    }

    constructor(raw) {
        super(raw);
        this.name = raw.name;
        this.email = raw.email;
        this.tel = raw.tel;
        this.address = new Address(raw.address);
    }
}

export default User;
