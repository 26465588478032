import React from 'react';
import clsx from 'clsx';
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LinkHomeLogo from 'components/LinkHomeLogo';

import {useAuth} from 'contexts/auth';


const useStyles = makeStyles(theme => ({
    bar: {
        zIndex: theme.zIndex.bar
    },
    logo: {
        height: 28
    },
    toolBar: {
        justifyContent: 'space-between'
    },
    user: {
        textDecoration: 'underline'
    }
}));

const TopBar = ({className}) => {
    const classes = useStyles();
    const {user} = useAuth();
    return (
        <AppBar
            className={clsx(classes.bar, className)}
            color={'inherit'}
            position={'sticky'}
            elevation={0}
        >
            <Toolbar className={classes.toolBar}>
                <LinkHomeLogo className={classes.logo}/>
                <Typography className={classes.user} component={'span'}>
                    {user?.name ?? ''}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;
