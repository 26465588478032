
class Address {
    constructor(raw) {
        this.postalCode = raw.postalCode;
        this.address1 = raw.address1;
        this.address2 = raw.address2;
    }
    toString() {
        return `${this.postalCode} ${this.address1} ${this.address2}`
    }
}

export default Address;
