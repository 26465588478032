import React, {lazy} from 'react';
import MainLayout from 'layouts/MainLayout';
import CloseLayout from 'layouts/CloseLayout';

export default [
    {
        title: 'B good',
        path: '/orders',
        exact: true,
        layout: CloseLayout,
        page: lazy(() => import('../pages/orders'))
    },
    {
        title: 'B good',
        path: '/order/:id',
        exact: true,
        layout: CloseLayout,
        page: lazy(() => import('../pages/order'))
    },
    {
        title: 'B good',
        path: '/products',
        exact: true,
        layout: CloseLayout,
        page: lazy(() => import('../pages/products'))
    },
    {
        title: 'B good',
        path: '/product/:id',
        exact: true,
        layout: CloseLayout,
        page: lazy(() => import('../pages/product'))
    },
    {
        title: 'B good',
        path: '/checkout',
        exact: true,
        layout: CloseLayout,
        page: lazy(() => import('../pages/checkout'))
    },
    {
        title: 'B good',
        path: '/',
        exact: true,
        layout: MainLayout,
        page: lazy(() => import('../pages/main')),
    },
]
