export default {
  'name': 'S&E Company',
  'email': 'contact@bgood.co.kr',
  'address': {
    postalCode: '03186',
    address1: '서울특별시 종로구 새문안로92 ',
    address2: '19층 1905호'
  },
  'businessNumber': '737-88-01827',
  'tel': '010-1111-2222'
};
