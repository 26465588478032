import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router';
import ErrorBoundary from './ErrorBoundary';
import ScrollToTop from './ScrollToTop';



const PrivateRoute = ({layout: Layout, component: Component, path, ...props}) => {
    const renderComponent = () => (
        <ScrollToTop>
            <Layout {...props}>
                <Component/>
            </Layout>
        </ScrollToTop>
    )
    return (
        <ErrorBoundary error={<p>Page has a error</p>}>
            <Route
                exact
                path={path}
                render={renderComponent}/>
        </ErrorBoundary>
    )
}


export default PrivateRoute;

