import useFetch from 'use-http';

class Model {
    //onNewData
    static useGet = async (url, args, options) => {
        const {get, response, loading, error} = useFetch(url);
        const data = await get();
        return {loading, error, data, status: response.status};
    }

    static usePost = async (url, body, options) => {
        const {post, response, loading, error} = useFetch(url);
        const data = await post(body);
        return {loading, error, data, status: response.status};
    }

    static usePut = async (url, body, options) => {
        const {put, response, loading, error} = useFetch(url);
        const data = await put(body);
        return {loading, error, data, status: response.status};
    }

    static useDelete = async (url, option) => {
        const request = useFetch(url);
        const {response, loading, error} = request;
        const data = await request.delete();
        return {loading, error, data, status: response.status};
    }

    static useFetch = useFetch;

    constructor({id}) {
        this.id = id;
    }
}

export default Model;
