import React, {Suspense} from 'react';
import {Route, Redirect, Switch} from 'react-router';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider as HttpProvider} from 'use-http';


import PrivateRoute from './components/PrivateRoute';
import routes from './config/routes';
import NoPage from './pages/404';
import Load from './pages/load';

import User from 'models/User';
import AuthContext from 'contexts/auth';

const httpOptions = {
    // suspense: true
};

export default function App() {
    return (
        <AuthContext.Provider value={User.useSignIn()}>
            <HttpProvider url='https://example.com' options={httpOptions}>
                <Suspense fallback={<Load/>}>
                    <Router>
                        <Switch>
                            {/*<Route path={'/login'} component={Login}/>*/}
                            {
                                routes.map(({path, layout, page, exact, ...route}, idx) => {
                                    return <PrivateRoute key={path} layout={layout} path={path}
                                                         component={page} exact={exact}
                                                         {...route}
                                    />
                                })
                            }
                            <Route path={'*'} component={NoPage}/>
                        </Switch>
                    </Router>
                </Suspense>
            </HttpProvider>
        </AuthContext.Provider>
    );
}
